export const staticApps: Common.App[] = [
    {
        image: "/img/apps/zeus.png",
        name: "Zeus",
        link: "http://af-zeus/ZeusWebservicesSSO",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/office.png",
        name: "My Office 365",
        link: "https://office.com",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/salesforce.png",
        name: "Salesforce",
        link: "https://august-faller.my.salesforce.com/",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/rexx-systems.png",
        name: "REXX Systems",
        link: "https://faller-packaging-hr.rexx-systems.com/rx/?targetRealm=rexx-hr&menu=1&script=start.php?mid%3D2",
        width: 250,
        height: 250,
        locations: ["Waldkirch", "Schopfheim", "Binzen"],
    },
    {
        image: "/img/apps/qlikview.png",
        name: "QlikView",
        link: "http://af-qlikview/qlikview/index.htm",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/qlikview.png",
        name: "QlikSense",
        link: "https://af-qliksense.ad.august-faller.de/hub/my/work",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/sharepoint.jpg",
        name: "Sharepoint",
        link: "https://fallerpackaging.sharepoint.com/",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/deepl.png",
        name: "Deepl Translator",
        link: "https://www.deepl.com/translator",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/faller.png",
        name: "Faller Packaging",
        link: "https://www.faller-packaging.com",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/onenote.svg",
        name: "M365 - FAQ",
        link: "https://fallerpackaging.sharepoint.com/teams/Office365-Training/_layouts/15/Doc.aspx?sourcedoc={11627fcf-14cc-436f-9b50-48cf4f3592d3}&action=edit&wd=target%28Allgemein.one%7C34e49dcb-f17e-4711-8189-fd72a5ed5378%2FAllgemeines%20%5C%7C%20General%7C41b9445f-ee9a-4ff7-8a3f-f1507b0a3d2d%2F%29",
        width: 250,
        height: 250,
        locations: ["Waldkirch", "Schopfheim", "Binzen", "Gebesee"],
    },
    {
        image: "/img/apps/twogo.svg",
        name: "TwoGo",
        link: "https://www.twogo.com/de/mitfahrzentrale/",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/detrack.svg",
        name: "ÖPNV Fahrplan",
        link: "https://fahrplan-bus-bahn.de/baden-wuerttemberg/freiburg/baden-wuerttemberg/waldkirch#/",
        width: 250,
        height: 250,
        locations: ["Waldkirch", "Schopfheim", "Binzen"],
    },
    {
        image: "/img/apps/corporate-benefits.png",
        name: "Mitarbeiterangebote (Corporate Benefits)",
        link: "https://faller-packaging.mitarbeiterangebote.de/login",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/kantin.png",
        name: "SICK-Kantine",
        link: "https://delightful.dussmann.com/menu/SICK%20AG/Betriebsrestaurant%20H-%20Geb%C3%A4ude%20",
        width: 250,
        height: 250,
        locations: ["Waldkirch"],
    },
    {
        image: "/img/apps/zeus-x.png",
        darkThemeImage: "/img/apps/zeus-x-dark-theme.png",
        name: "ZeusX SSO",
        link: "https://af-zeusx-web.ad.august-faller.de/ZEUSX/?idp=39bd8c47-6022-44e9-8a70-ed4830b8afc1",
        width: 250,
        height: 250,
    },
    {
        image: "/img/apps/zeus-x.png",
        darkThemeImage: "/img/apps/zeus-x-dark-theme.png",
        name: "ZeusX Login",
        link: "https://af-zeusx-web.ad.august-faller.de/ZEUSX/",
        width: 250,
        height: 250,
    },
];
